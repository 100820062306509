import React from "react";
import { Card } from 'react-bootstrap';
import { showInParagraphs } from "./utils";

export default function RoleOrServiceCard({ data }) {
    // const truncateText = (text, maxLength) => {
    //     if (text.length > maxLength) {
    //         return text.substring(0, maxLength) + "...";
    //     }
    //     return text;
    // };
    const handleClick = () => {
        window.location.href = "hireme/" + data.Title; // or use a router push if using React Router
      };

    return (
        <Card className = "h-100 shadow-sm bg-white rounded">
            {/* <Card.Img variant="top" src={"./images/"+ data.image} /> */}
            <Card.Body className="d-flex flex-column">
                {/* <div className="d-flex mb-2 justify-content-between"> */}
                    <Card.Title className="mb-0 font-weight-bold text-accent2" onClick={handleClick}>{data.Title}</Card.Title>
                {/* </div> */}
                <hr/>
                <Card.Text className="text-accent3" onClick={handleClick}>{showInParagraphs(data.Description)}</Card.Text>
            </Card.Body>
        </Card>
    )
}