import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { content } from "./content";
import ArticleCard from "./articlecard";

export default function Articles() {
    return (
      <>
        <Container fluid>
          <Row className="mt-1 ms-1">
            {content.articles.map((article, index) => (
              <Col sm={6} lg={3} className="mb-5" key={"article_" + index}>
                <ArticleCard data={article} />
              </Col>
            ))}
          </Row>
        </Container>
      </>
    );
  }
  