import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function AboutMe() {
  return (
    <Container fluid className="bg-light text-accent1">
      <Row>
        <Col lg={4} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/rick_1024.jpg"
              alt="Introduction"
              className="circular-image"
            />
          </div>
          <h2>Nice to meet you</h2>
          <p>
            I’m Rick, an experienced technology leader with a diverse background
            that spans software engineering, consulting, and executive roles
            such as Director, CIO, and CTO.
          </p>
          <p>
            I'm available for Interim roles, coaching or mentoring or help you
            address a specific issue.
          </p>
          <p>
            I provide my services on a fractional or interim basis, allowing
            organizations to benefit from my skills and experience without the
            long-term financial commitment of a permanent hire.
          </p>
        </Col>
        <Col lg={4} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="/images/code-1000.jpg"
              alt="Origin"
              className="circular-image"
            />
          </div>
          <h2>Technical Roots</h2>
          <p>
            Though my career has evolved beyond hands-on coding, my passion for
            technology remains strong.
          </p>
          <p>
            My background in software engineering helps me to bring technical
            expertise to my leadership roles. I leverage my knowledge to bridge
            the gap between technical and non-technical stakeholders, ensuring
            that decisions are both strategic and technically sound.
          </p>
          <p>
            While I'm not professionally coding anymore, I keep myself up to
            date through personal projects and will get my hands dirty if this
            saves time e.g. doing scripting, creating
            dashboards or tuning configurations.
          </p>
        </Col>
        <Col lg={4} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/pexels-taryn-elliott-4253928.jpg"
              alt="Values"
              className="circular-image"
            />
          </div>
          <h2>Going Global</h2>
          <p>
            My personal journey has taken me from the Netherlands to Australia, Ireland,
            France and South Africa. Having worked with clients as well as remote teams around 
            the globe furher exposed me to a wide range of industries, companies and cultures. 
          </p>
          <p>
            My experience working across different countries, industries, and
            company cultures has given me the ability to navigate and manage
            diverse teams effectively.
          </p>
          <p>
            My global perspective, cultural awareness, and adaptability are
            central to my leadership approach, helping me to build inclusive
            environments and guide teams through the complexities of
            multicultural dynamics. This experience has also strengthened my
            communication and stakeholder management skills, making me a
            valuable leader in international and diverse settings.
          </p>
        </Col>
        <Col lg={4} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/rick_goto2024.jpg"
              alt="Journey"
              className="circular-image"
            />
          </div>
          <h2>Mentorship, Coaching, and People-Centric Leadership</h2>
          <p>
          My journey from
            hands-on coding to leadership roles like Director, CIO, and CTO has
            equipped me with a deep understanding of both the operational and
            strategic aspects of business. This experience, combined with my
            proficiency in Agile methodologies is helping me mentor and coach teams, engineers and leaders.
          </p>
          <p>
            I am dedicated to mentoring and coaching, with creating win-win
            situations for the organization as well as individuals. My
            people-centric approach, coupled with objectivity and logical
            guidance, fosters trust and collaboration within teams. I strive to
            provide constructive feedback, resolve conflicts, and inspire teams
            to reach their full potential. My human-centric leadership ensures
            that both technical and operational improvements are sustainable and
            aligned with the well-being of team members.
          </p>
        </Col>
        <Col lg={4} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/pexels-pixabay-276272.jpg"
              alt="Orchestrating Transitions"
              className="circular-image"
            />
          </div>
          <h2>Orchstrating Transitions.... </h2>
          <p>
            I'd like to think that I bring a unique blend of
            technical expertise, strategic insight, and global experience to
            help organizations navigate complex transitions. 
          </p>
          <p>
            I combine strategic thinking with a calm, logical
            approach to problem-solving, ensuring that decisions are
            well-considered and outcomes are sustainable. 
            </p>
            <p>My capacity for
            balancing independent work with strong, collaborative leadership
            enables me to align diverse teams around common goals, making me the
            ideal partner for guiding organizations through their most difficult
            transitions.
          </p>
        </Col>
      </Row>
      <Row className="text-center">
        <Col className="mb-5">
          <a
            href={"/connect"}
            className="btn btn-primary mt-auto font-weight-bold"
          >
            Lets connect
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutMe;
