import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import roleOrServicesData from "./content/roleorservice.json";
import differentiatorData from "./content/differentiators.json";
import benefitData from "./content/benefits.json";
import { showInParagraphs } from "./utils";

export default function RoleOrService() {
  const { id } = useParams(); // Extract the ID from the URL
  const roleOrServiceData = roleOrServicesData.find(
    (roleOrService) => roleOrService.Title === id
  );
  const differentiators = roleOrServiceData.Differentiators.split(",");

  const benefits = roleOrServiceData.Benefits.split(",");
  // for each of the differentiators, find the description
  const differentiatorDescriptions = differentiators.map((differentiator) => {
    const description = differentiatorData.find(
      (data) => data.Title === differentiator
    );
    return description ? description.Description : "";
  });
  // for each of the benefits, find the description
  const benefitDescriptions = benefits.map((benefit) => {
    const description = benefitData.find(
      (data) => data.Title === benefit.trim()
    );
    return description ? description.Description : "";
  });
  // achievements are in the benefits data stored as ProofTitle & ProofDescription
  const achievements = benefits.map((benefit) => {
    const proofDetails = benefitData.find(
      (data) => data.Title === benefit.trim()
    );
    return {
      title: proofDetails && proofDetails.ProofTitle,
      description: proofDetails && proofDetails.ProofDescription,
    };
  });
  // create an array of cards with all benefits, differentiators, and achievements
  const cards = [];
  benefits.forEach((benefit, index) => {
    cards.push([benefit, benefitDescriptions[index]]);
  });
  differentiators.forEach((differentiator, index) => {
    cards.push([differentiator, differentiatorDescriptions[index]]);
  });
  achievements.forEach((achievement) => {
    cards.push([achievement.title, achievement.description]);
  });
  console.log(roleOrServiceData.image);
  return (
    <>
      <Container fluid className="bg-light">
        <Row>
          <Col className="text-center text-info mt-5 mb-5 d-flex justify-content-center align-items-center">
            <h1>{roleOrServiceData.Title}</h1>
          </Col>
          <Col className="mt-4 mx-5 mb-5 text-accent1 justify-content-center align-items-center">
            {showInParagraphs(roleOrServiceData.Description)}
          </Col>
        </Row>
        <Row className="d-flex">
          <Col sm={12} md={6} lg={3} className="text-left mb-4" key={0}>
            <Card className="h-100">
              <Card.Img
                src={"../images/" + roleOrServiceData.image}
                className="h-100"
              />
            </Card>
          </Col>

          {cards.map((card, index) => (
            <Col
              sm={12}
              md={6}
              lg={3}
              className="text-left mb-4"
              key={index + 1}
            >
              <Card className="h-100">
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="text-accent2">{card[0]}</Card.Title>
                  <Card.Text className="flex-grow-1">{showInParagraphs(card[1])}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className="text-center">
          <Col className="mb-5">
            <a
              href={"/connect"}
              className="btn btn-primary mt-auto font-weight-bold"
            >
              Lets connect
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}


