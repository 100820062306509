export const content = {
  service_categories: [
    {
      name: "Why hire me",
      image: "/images/team-planning-1000.jpg",
      description:
      <>
      <p>Embarking on a fulfilling journey, I've had the privilege of contributing to the success of software engineering projects. With experience in guiding delivery organizations and consulting endeavors, I've had the opportunity to work across various industries and regions, assisting both startups and established enterprises.</p>
      <p>A core aspect of my approach has been improving team performance and platform stability by thoughtfully aligning goals, processes, organization, and tools. Amid this, my focus remains on fostering growth, both for individuals and the organizations they're a part of. I find immense satisfaction in identifying mutually beneficial opportunities and offering mentorship and coaching to nurture talent.</p>
      <p>My strength lies in distilling complex data into clear reports and visuals that promote effective communication and collaboration. I believe in blending technical expertise with a personable demeanor, especially in bridging gaps between technical and commercial teams.</p>
      <p>As a Strategic Software Leader, I'm humbled to have been a part of projects that found success and teams that thrived. My journey speaks to the power of strategy, teamwork, and continuous growth. I invite you to join me on this path of contribution, where every step is a chance to learn and make a positive impact.</p>
      </>
    },
    {
      name: "Interim/Fractional/Project or Program Management",
      image: "/images/wet-web-1000.jpg",
      description:
      <>
        <p>Temporarily in need an experienced manager to address a skills gap in your technology team leadership team or in need of a fresh persperspective, consider me as an interim solution</p>
        <p>Do you want to increase the likelyhood of your project to be succesful. Consider hiring me as a Project Manager.</p>
        <p>You might not have the resources, budget or workload to hire a full time CTO, CPTO or VP of Engineering. In that case consider the fractional model: an experienced professional helping you for a fraction of the time against a fraction of the cost.</p>
        <p>Over the years I've learned how to adapt fast to new environments and become effective, respecting your ways of working while bringing new insights on how you can improve</p>
      </>      
      },
    {
      name: "What problems can I help you solve",
      image: "/images/code-1000.jpg",
      description:
        <>
          <p>I can be your partner in guiding your organization towards agile methodologies, leading the way to <b>streamlined processes</b>, enhanced collaboration, and increase project success rates.</p>
          <p>I specialize in <b>bridging the divide between technical and non-technical teams</b>, ensuring better communication and collaboration. By aligning stakeholders with diverse interests, I aim to foster a common understanding and facilitating decision-making.</p>
          <p>I believe in the power of insights from data to make informed decisions and execute strategic plans with confidence. You might be looking to improve impact, stability, througput or quality. Whether its KPIs, OKR, personal performance, 360s, etc. I'm happy to help you gather, analyze and visualize the right data and measure the right things in order to improve.</p>
          <p>TODO FROM HERE</p>
          <p>Maximize Team Performance: With my expertise, your teams can achieve peak performance. I'll identify bottlenecks and implement strategies that will boost productivity and efficiency to new heights.</p>          
          <p>Empower Through Mentorship and Coaching: Extend your team's capabilities through my mentorship and coaching services, designed to foster professional growth and development.</p>
          <p>Engineer Effective Processes: Collaborate with me to uncover inefficiencies in your processes and engineer tailor-made solutions for heightened effectiveness and resource utilization.</p>
          <p>Drive Technology Standardization: I specialize in helping organizations standardize their technology stack and practices, enabling scalability, ease of maintenance, and seamless integration.</p>
          <p>Prioritize Value for Success: I introduce value-based prioritization methods to guide your decision-making, focusing your efforts on high-impact initiatives that drive success.</p>
          <p>Cultivate Effective Leadership: Let me cultivate leadership within your organization through coaching and training. Develop effective leaders who can lead teams to success.</p>
          <p>Embrace Digital Transformation: Embrace digital transformation with my guidance. I'll assess your current state, recommend technology adoption, and smoothly guide you through the transition.</p>          
          
        </>
    },
  ],
  connect_info: [
    {
      header: "LinkedIn",
      type: "link",
      details: "https://www.linkedin.com/in/rickpmolenaar/",
    },
    {
      header: "Instagram",
      type: "link",
      details: "https://www.instagram.com/rickpmolenaar/",
    },
    {
      header: "Medium",
      type: "link",
      details: "https://medium.com/@rick_42462",
    },
    {
      header: "Toptal",
      type: "link",
      details: "https://www.toptal.com/project-managers/resume/rick-molenaar",
    }
  ],
  intro: [
    "Every company needs Technical Leadership (e.g. a CTO/CTO/ VP of Engineering etc) in order to keep their competitive advantage. These professionals are  typically in charge of the technology, the tech team and/or vendors. They promote efficiency and innovation throughout the company, but also secure important initiatives  such as technical  integration, transformation, automation, stabilisation & improvement projects",
    "The expense of hiring technical leadership full-time is typically very high and the market for technical leaders is extremely difficult which makes having these professionals on your team permanently a challenge.",
    "For some companies it might be worthwhile to consider a less costly part-time or temporary solution (referred to as “Fractional”). This way  you can reap the benefits of the services provided by these professionals at a fraction of the cost.",
    "Find out how I can help you below or browse the menu to learn more about me or find out how to get in touch",
    "Have a great day !",
  ],
  articles: [
    {
      title: "The colours of Tech Leadership",
      date: "August 30, 2022",
      image: "/images/colors-of-tech-leadership.jpeg",
      link: "the_colours_of_tech_leadership",
      summary:
        "Finding a good way to do Tech Leadership is tough. When dumbing down the problem for people I often refer the Insights Discovery personality colours (with a slightly different interpretation) and how a lack of one or more of the colours can affect the teams impact.",
    },
    {
      title: "Fun with flags",
      date: "September 16, 2022",
      image: "/images/fun_with_flags.png",
      link: "fun_with_flags",
      summary:
        "Having lived in 5 different countries, we recently moved back to the Netherlands where currently the upside down version of our flag is being used as a symbol of protest by farmers against the national government. It initially made me think of Sheldon's vlog \"fun with flags\" in the Big Bang Theory. It also made me think about what the national flags I've lived under represent for me.",
    },
    {
      title: "Finding Flow",
      date: "September 7, 2022",
      image: "/images/finding_flow.png",
      link: "finding_flow",
      summary:
        'Instead of referring to "Process", I prefer to refer to "Flow". Flow implies progress and is something you can maintain for a long time. Finding that flow for yourself, your team and/or your organisation is a nice challenge, but very rewarding when you find it !.',
    },
    {
      title: "Back to the (coding) rabbit holes",
      date: "May 25, 2022",
      image: "/images/back_to_the_coding_rabbit_holes.jpeg",
      link: "back_to_the_coding_rabbit_holes",
      summary:
        "I like getting up early and trying to catch the sunrise with my dog Harry. Usually I would then soon start my journey to work, but Covid changed all that. I decided to use the extra hour in the morning to get back into coding. It's been over 15 years since I was seriously coding so it was a bit of a challenge to actually practice what I preached for so long. Here's my main insights on how the developer experience changed.",
    },
  ],
};
