import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import roleOrServices from "./content/roleorservice.json";
import RoleOrServiceCard from "./roleorservicecard";

export default function HireMe() {
  return (
    <>
      <>
        <Container fluid>
          <Row className="mt-3 mb-3 mx-3">
            <Col lg={3} className="mt-3 msb-2">
              <h1 className="text-accent1">Hire me</h1>
            </Col>
            <Col>
              <p className="text-accent1">I can Provide high-impact leadership and expertise on a fractional or interim basis, allowing organizations to benefit from top-tier skills and experience without the long-term financial commitment of a permanent hire</p>
            </Col>
          </Row>
          <Row className="mt-1 ms-1">
            {roleOrServices.map((roleOrService, index) => (
              <Col sm={12} md={7} lg={4} className="mb-5" key={"roleorservice_" + index}>
                <RoleOrServiceCard data={roleOrService} />
              </Col>
            ))}
          </Row>
        </Container>
      </>
    </>
  );
}
